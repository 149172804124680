import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const ParagraphText = styled(Typography)`
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  /* -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #000; */
  line-height: ${(props) => (props.lh ? props.lh : "1.1")};
  position: relative;
  z-index: 1;
  @media (min-width: 1440px) {
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "22px")};
  }
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "16px")};  
  }
`;

const Paragraph = (props) => {
  const { children } = props;
  return (
    <ParagraphText
      color="#000"
      fontFamily="GaeguB"
      fontSize="16px"
      textAlign="left"
      padding="0px"
      margin="0px"
      lineHeight={1.1}
      {...props}
    >
      {children}
    </ParagraphText>
  );
};

export default Paragraph;
