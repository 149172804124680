import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Merchandise from "../../Components/Sections/S3-Merchandies";
import Tokenomics from "../../Components/Sections/S4-Tokenomics";
import Memes from "../../Components/Sections/S5-Memes";
import Community from "../../Components/Sections/S6-Comunity";
import styled from "@emotion/styled";
import Video from "../../../assets/images/backgroundVideo.mp4";

const BackgroundVideo = styled.video`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 0;
`;
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
     <BackgroundVideo src={Video} muted autoPlay loop />
    <PageWrapper>
      <HeroSection />
      <AboutSection/>
      <Merchandise/>
      <Tokenomics/>
      <Memes/>
      <Community/>
    </PageWrapper>
    </>
  );
};

export default MainPage;
