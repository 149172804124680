import * as React from "react";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import pic2 from "../../../../assets/images/Image2.png";
import pic3 from "../../../../assets/images/Image3.png";
import pic4 from "../../../../assets/images/Image4.png";
import pic5 from "../../../../assets/images/Image5.png";
import pic6 from "../../../../assets/images/Image6.png";
import pic7 from "../../../../assets/images/Image7.png";
import pic15 from "../../../../assets/images/Image15.png";
import pic17 from "../../../../assets/images/Image17.png";
import pic18 from "../../../../assets/images/Image18.png";
import memeHeading from "../../../../assets/images/memeHeading.png";
import { DataImage } from "../../../Styles/style";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  z-index: 0;
  /* ::after {
    content: "";
    background-color: #000000be;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 0;
  } */
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  @media (max-width: 599px) {
  }
`;
const BoxWrapper = styled(Grid)`
  @media (min-width: 601px) {
    display: flex;
    justify-content: center;
  }
`;
const ImgWrap = styled.div`
  line-height: 4;
  -webkit-column-count: 5;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 2;
  column-gap: 20px;
  img {
    width: 100% !important;
    height: auto !important;
  }
  video {
    width: 100% !important;
    height: auto !important;
  }
  display: inline-block;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 600px) {
    line-height: 2;
    -webkit-column-count: 5;
    -webkit-column-gap: 0px;
    -moz-column-count: 5;
    -moz-column-gap: 0px;
    column-count: 1;
    column-gap: 15px;
  }
`;

export default function MemeRadar() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <>
      <SectionWraper>
        <InnerContinerWraper >
            <BoxWrapper container>
              <Grid item xs={12} sx={{ display: "grid", placeItems: "center",margin:"30px 0" }}>
                <DataImage mw="40%" mw599="90%" src={memeHeading} />
              </Grid>
              <ImgWrap>
                <img alt="" src={pic3} />
                <img alt="" src={pic5} />
                <img alt="" src={pic2} />
                <img alt="" src={pic4} />
                <img alt="" src={pic6} />
                <img alt="" src={pic7} />
                <img alt="" src={pic15} />
                <img alt="" src={pic17} />
                <img alt="" src={pic18} />
              </ImgWrap>
            </BoxWrapper>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
}
