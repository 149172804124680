import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import logo from "../../../../assets/images/logo.png";
import dev from "../../../../assets/images/dev.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { CustomGrid, DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 40vh;
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(40vh - 0px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const Community = () => {
  return (
    <>
      <SectionWraper id="Community">
        <InnerContinerWraper sx={{ paddingY: "50px" }}>
          <CustomGrid container fd="column" g="20px">
            <DataImage mw="150px" src={logo} />
            <DataImage mw="150px" src={dev} />
            <CustomGrid item xs={12} g="20px">
              <CustomButton href="https://t.me/Deverc20" target="blank" p="10px 8px" w="20px">
                <DataImage mw="22px" src={tg} />
              </CustomButton>
              <CustomButton href="https://twitter.com/Deverc20" target="blank" p="10px" w="20px">
                <DataImage mw="19px" src={x} />
              </CustomButton>
            </CustomGrid>
            <Paragraph color="#fff" fs="22px" fs599="20px">info@devdoingsomething.com</Paragraph>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Community;
