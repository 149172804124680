import React from "react";
import { Container} from "@mui/material";
import styled from "@emotion/styled";
import Merchtxt from "../../../../assets/images/Merchandiestxt.png";
import Shirt from "../../../../assets/images/shirt.png";
import Pant from "../../../../assets/images/pant.png";
import { CustomGrid} from "../../../Styles/style";
import CustomButton from "../../CustomBtn";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height:calc(100vh);
  width: 100%;
  padding:20px;
  @media (max-width: 599px) {
  }
`;

const Merchtxtimg = styled.img`
  width:58%;
  height: auto;
  margin:30px 10px; 
`

const BlackDiv= styled.div`
  background-color: black;
  text-align: center;
  margin: 25px;
  padding:30px;
  `
const Shirtimg = styled.img`
  width: 100%;
  height: auto;
`
const Pantimg = styled.img`
  width:99%;
  height: auto;
`
const Merchandise = () => {
  return (
    <>
      <SectionWraper>
        <InnerContinerWraper>
          <CustomGrid container fd="column" >
            <Merchtxtimg src={Merchtxt}/>
            <CustomGrid item xs={12}  sx={{flexDirection:{xs:"column", md:"row"}}}>
              <BlackDiv> <Shirtimg src={Shirt}/> </BlackDiv>
              <BlackDiv> <Pantimg src={Pant}/> </BlackDiv>
            </CustomGrid>
            <CustomButton p="2px 30px" fs="30px" disabled={true} bgc="linear-gradient(to bottom,#5A5A5A,#fff,#5A5A5A)">Store Live Soon</CustomButton>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Merchandise;
