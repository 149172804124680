import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteImage,
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import heroBg from "../../../../assets/images/heroBg.png";
import heroImage from "../../../../assets/images/heroImage.png";
import music from "../../../../assets/images/music.mp3";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh);
  background-image: url(${heroBg});
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 54px);
  width: 100%;
  z-index: 2;
  position: relative;
  @media (max-width: 599px) {
    min-height: calc(100dvh - 48px);
  }
`;

const HeroSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <>
      {isPlaying && (
        <audio autoPlay loop>
          <source src={music} type="audio/mpeg" />
        </audio>
      )}
      <SectionWraper id="home">
        <Box
          sx={{
            fontSize: { xs: "16px", sm: "22px" },
            fontFamily: "GaeguB",
            color: "#fff",
            background: "#000",
            textAlign: "center",
            wordBreak: "break-all",
          }}
        >
          Contract Address: 0000000X000x0000x00000000xxxx00
        </Box>
        <AbsoluiteImage
          mw="65%"
          mw999="80%"
          style={{ bottom: "0", left: "0" }}
          src={heroImage}
        />
        <InnerContinerWraper>
        <div
          style={{
            position: "absolute",
            top: "5%",
            right: "5%",
            cursor: "pointer",
            zIndex:"9"
          }}
          onClick={() => setIsPlaying(!isPlaying)}
        >
          {isPlaying ? <VolumeUpIcon sx={{fontSize:"40px"}}/> : <VolumeOffIcon sx={{fontSize:"40px"}}/>}
        </div>
          <CustomGrid container jc="end">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="start"
              g="20px"
              sx={{
                transform: { xs: "none", sm: "rotate(-10deg)" },
                margin: { xs: "15% 0 0", sm: "0% 0 0" },
              }}
            >
              <SectionHeading c="#006934">
                Dev <br /> doing <br /> something
              </SectionHeading>
              <Paragraph>
                Hello ser, <br />
                <br />
                Welcome to my project. I am very very based, together we will
                moon and jeet.
                <br />
                <br />
                Please buy merhandies, ser!
              </Paragraph>
              <div style={{ display: "flex", gap: "20px" }}>
                <CustomButton>Buy Now</CustomButton>
                <CustomButton bgc="#000" hbgc="#000" c="#fff" hc="#fff">
                  View Chart
                </CustomButton>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>{" "}
      </SectionWraper>
    </>
  );
};

export default HeroSection;
