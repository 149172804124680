import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import tokenomicsBg from "../../../../assets/images/tokenomicsBg.png";
import tokenHeading from "../../../../assets/images/tokenHeading.png";
import supply from "../../../../assets/images/supply.png";
import taxes from "../../../../assets/images/taxes.png";
import CA from "../../../../assets/images/CA.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;
  background-image: url(${tokenomicsBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;

  @media (max-width: 599px) {
  }
`;
const StyledSectionHeading = styled(SectionHeading)`
  /* white-space: nowrap; */
  font-size: ${(props) => (props.fs ? props.fs : "70px")};
  @media (min-width: 1440px) {
    font-size: ${(props) => (props.fs1440 ? props.fs1440 : "70px")};
  }
  @media (max-width: 1175px) {
    font-size: ${(props) => (props.fs1175 ? props.fs1175 : "66px")};
  }
  @media (max-width: 1024px) {
    font-size: ${(props) => (props.fs1024 ? props.fs1024 : "60px")};
  }
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "40px")};
  }
`;
const Styledspan = styled.span`
  /* white-space: nowrap; */
  font-size: ${(props) => (props.fs ? props.fs : "50px")};
  @media (min-width: 1440px) {
    font-size: ${(props) => (props.fs1440 ? props.fs1440 : "50px")};
  }
  @media (max-width: 1175px) {
    font-size: ${(props) => (props.fs1175 ? props.fs1175 : "46px")};
  }
  @media (max-width: 1024px) {
    font-size: ${(props) => (props.fs1024 ? props.fs1024 : "30px")};
  }
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "30px")};
  }
`;
const Tokenomics = () => {
  const [contractAddress, setcontractAddress] = React.useState(
    "0000000X000x0000x00000000xxxx00"
  );
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper >
          <CustomGrid container fd="column" ai="start" g="20px">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="start"
              g="20px"
              sx={{
                background: "transparent",
                zIndex: 1,
                position: "relative",
              }}
            >
              {isAlertVisible && (
                <div className="alert-container">
                  <div style={{ transition: "1s" }} className="alert-inner">
                    <Paragraph ta="center" fs="26px" fs599="16px">
                      Contract Address Copied
                    </Paragraph>
                  </div>
                </div>
              )}
              <DataImage m="10px 0" mw="85%" src={tokenHeading} />
              <DataImage mw="18%" mw599="28%" src={supply} />
              <StyledSectionHeading>1,428,627,663</StyledSectionHeading>
              <DataImage mw="20%" mw599="30%" src={taxes} />
              <StyledSectionHeading>1/1<Styledspan>Tax For The Village</Styledspan></StyledSectionHeading>
              <DataImage mw="50%" mw599="80%" src={CA} />
            </CustomGrid>
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={5}
              ai="center"
              jc="space-between"
              p="10px 20px"
              sx={{ background: "#ffffff", zIndex: 1, borderRadius: "10px" }}
            >
              <Paragraph fs="20px" fs599="18px" sx={{wordBreak:"break-all"}}>
                {contractAddress}
              </Paragraph>
              <FileCopyIcon
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              />
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Tokenomics;
