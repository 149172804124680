import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import aboutBg from "../../../../assets/images/aboutBg.png";
import aboutImage from "../../../../assets/images/aboutImage.png";
import CustomButton from "../../CustomBtn";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  background-color: #1c1c1c;
  width: 100%;
  position: relative;
  background-image: url(${aboutBg});
  background-size: cover;
  background-position: center;
  background-repeat: none;
  
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  padding-top: 5%;
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: start;
  @media (max-width: 599px) {
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionWraper id="about">
        <AbsoluiteImage mw="65%" style={{ bottom: "0", right: "0" }} src={aboutImage} />
        <InnerContinerWraper >
          <CustomGrid container jc="start">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="start"
              g="20px"
              m="5% 0 0"
              sx={{transform: {xs:"none", sm:"rotate(-10deg)",margin: { xs: "15% 0 0", sm: "0% 0 0" },}}}
            >
              <SectionHeading sx={{whiteSpace:"nowrap"}}>Meet Steve</SectionHeading>
              <SectionHeading fs="45px" fs599="23px" fs1024="42px" fs1440="70px" sx={{whiteSpace:"nowrap"}} >("Grounded Vindaloop")</SectionHeading>
              <Paragraph color="white">
              On a cold night on a Mumbai street sat Apu,<br/>
              where he sat contemplating while taking a poo,<br/>
              Got a funny idea, to Dubai he'd go too.<br/>
              As Steve, he'd make a plan,<br/> 
              "Dev doing something" in his hand.<br/>
              <br/>So join the fun, don't be shy,<br/> 
               Buy some "$DEV," reach for the sky!
              </Paragraph>
              <div style={{ display: "flex", gap: "20px" }}>
              <CustomButton href="https://t.me/Deverc20" target="blank" p="10px 8px" w="20px">
                <DataImage mw="22px" src={tg} />
              </CustomButton>
              <CustomButton href="https://twitter.com/Deverc20" target="blank" p="10px" w="20px">
                <DataImage mw="19px" src={x} />
              </CustomButton>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default AboutSection;
